<template>
  <v-col class="containCommand">
    <v-card
      :disabled="disabled"
      id="customizedCard"
      :elevation="theme === 'dark' ? 12 : 2"
      :dark="theme === 'dark'"
      :style="`  background-color: ${theme === 'dark' ? ' transparent' : 'white'};`"
      class="mx-auto my-1"
      max-width="580"
      width="150"
      :height="setPointError ? '185' : '145'">
      <span class="flexClass pt-1" style="font-size: x-small"> {{ compartmentSetpoint }}</span>
      <div class="flexClass mb-2" style="display: flex; flex-wrap: wrap; justify-content: space-around; height: 1em">
        <v-checkbox disabled v-model="truOnOff" label="TRU ON/OFF" dense class="small-checkbox"></v-checkbox>
      </div>
      <v-card-text ref="probe">
        <v-text-field
          :disabled="disabled"
          :style="{ marginBottom: commandNameError ? '10px' : '0px' }"
          class="small-label"
          @input="editing"
          @keyup="onBlur"
          dense
          v-model="commandName"
          type="string"
          :label="this.$t('general.dialog.eventDialog.name')"
          :rules="nameRules" />
        <v-text-field
          :disabled="disabled"
          @input="editing"
          @keyup="onBlur"
          class="small-label"
          dense
          v-model="commandSetpoint"
          type="number"
          :label="this.$t('dashboard.configuration.modify_setpoint')"
          :rules="tempRules" />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
const ls = require('localstorage-slim');
import { eventBus } from '../../main';

export default {
  props: {
    compartmentSetpoint: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },

  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    commandName: '',
    commandSetpoint: '',
    truOnOff: false,
    setPointError: false,
    commandNameError: false,
  }),

  methods: {
    editing() {
      this.$emit('editing');
    },

    onBlur() {
      if (this.commandName == '' && this.commandSetpoint == '') {
        this.$emit('finish-editing');
      }
    },

    resetLimits() {
      this.commandName = '';
      this.commandSetpoint = null;
      this.$emit('finish-editing');
    },
  },

  computed: {
    tempRules() {
      eventBus.$emit('selectorSetpointData', this.commandSetpoint);

      return [
        (value) => (Number(value) >= -30 && Number(value) <= 35) || this.$t('dashboard.configuration.value_should_be_between'),
      ];
    },
    nameRules() {
      const regex = /^(?!.*[~˜])[a-zA-Z0-9-\s]{1,20}$/;

      eventBus.$emit('selectorNameData', this.commandName);

      return [(value) => regex.test(value) || value == '' || this.$t('dashboard.configuration.max_character')];
    },
  },
  watch: {
    commandSetpoint(value) {
      this.setPointError = Number(value) > 35 || Number(value) < -30;
    },
    commandName(value) {
      const regex = /^(?!.*[~˜])[a-zA-Z0-9-\s]{1,20}$/;
      this.commandNameError = !regex.test(value) && value !== '';
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    eventBus.$on('close-command-modal', this.resetLimits);

    // STYLE FOR CUSTOMCARD
    const CONTAINER = document.querySelector('.containCommand');
    const CARDS = document.querySelectorAll('#customizedCard');

    const CONFIG = {
      proximity: 40,
      spread: 80,
      blur: 20,
      gap: 32,
      vertical: false,
      opacity: 0,
    };

    const UPDATE = (event) => {
      for (const CARD of CARDS) {
        const CARD_BOUNDS = CARD.getBoundingClientRect();
        if (
          event?.x > CARD_BOUNDS.left - CONFIG.proximity &&
          event?.x < CARD_BOUNDS.left + CARD_BOUNDS.width + CONFIG.proximity &&
          event?.y > CARD_BOUNDS.top - CONFIG.proximity &&
          event?.y < CARD_BOUNDS.top + CARD_BOUNDS.height + CONFIG.proximity
        ) {
          CARD.style.setProperty('--active', 1);
        } else {
          CARD.style.setProperty('--active', CONFIG.opacity);
        }
        const CARD_CENTER = [CARD_BOUNDS.left + CARD_BOUNDS.width * 0.5, CARD_BOUNDS.top + CARD_BOUNDS.height * 0.5];
        let ANGLE = (Math.atan2(event?.y - CARD_CENTER[1], event?.x - CARD_CENTER[0]) * 180) / Math.PI;
        ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE;
        CARD.style.setProperty('--start', ANGLE + 90);
      }
    };

    document.body.addEventListener('pointermove', UPDATE);

    const RESTYLE = () => {
      CONTAINER.style.setProperty('--gap', CONFIG.gap);
      CONTAINER.style.setProperty('--blur', CONFIG.blur);
      CONTAINER.style.setProperty('--spread', CONFIG.spread);
      CONTAINER.style.setProperty('--direction', CONFIG.vertical ? 'column' : 'row');
    };

    RESTYLE();
    UPDATE();
  },
};
</script>
