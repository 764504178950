const { saveCommandDevice } = require('../server/petitions/commands/saveCommands');
const userViewModel = require('./userViewModel');

const CommandsViewModel = function () {
  const saveCommand = async (payload) => {
    const token = userViewModel.getToken();
    const response = await saveCommandDevice(token, payload);
    return response;
  };

  return {
    saveCommand,
  };
};

module.exports = CommandsViewModel();
