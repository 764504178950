const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class SaveCommandDeviceException extends PetitionError {
  static errorToSaveCommand = 'ERROR_TO_SAVE_COMMAND';

  constructor(code) {
    super('Save command', code);
  }
}

async function saveCommandDevice(token, payload) {
  const response = await post('commands/', payload, token);
  if (response.result !== SaveCommandDeviceException.success) {
    return new SaveCommandDeviceException(response.result);
  }
  return response;
}

module.exports = { saveCommandDevice, SaveCommandDeviceException };
