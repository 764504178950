const urlCarrier = 'https://api.fleet.lynx.carrier.io/v1/';
const urlCommands = 'https://api.fleet.lynx.carrier.io/2waycmd/v1/';
const getCarrierAssets = async (apiKey) => {
  const myHeaders = new Headers();
  myHeaders.append('x-lynx-api-key', apiKey);

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  let resultJSON;
  try {
    const response = await fetch(`${urlCarrier}assets`, requestOptions);
    const result = await response.text();
    resultJSON = JSON.parse(result);
  } catch (error) {
    console.log('error', error);
  }

  return resultJSON;
};

const getFrameMachineFromCarrier = async (apiKey, assetId, startDate, endDate) => {
  const myHeaders = new Headers();
  myHeaders.append('x-lynx-api-key', apiKey);
  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const getCarrierData = async (startDate, endDate, assetId, nextToken) => {
    let response;
    if (nextToken) {
      response = await fetch(
        `${urlCarrier}asset-history-items?startDate=${startDate}&endDate=${endDate}&assetId=${assetId}&limit=250&nextToken=${nextToken}`,
        requestOptions,
      );
    } else {
      response = await fetch(
        `${urlCarrier}asset-history-items?startDate=${startDate}&endDate=${endDate}&assetId=${assetId}&limit=250`,
        requestOptions,
      );
    }
    const result = await response.text();
    resultJSON = JSON.parse(result);
    return resultJSON;
  };

  let carrierData;
  let nextToken;
  let carrierDataWithToken;
  try {
    carrierData = await getCarrierData(startDate, endDate, assetId);

    nextToken = carrierData.nextToken;

    for (let index = 0; index < 20; index++) {
      if (nextToken) {
        carrierDataWithToken = await getCarrierData(startDate, endDate, assetId, nextToken);

        carrierData.data.push(...carrierDataWithToken.data);
        nextToken = carrierDataWithToken.nextToken;
      }
    }
  } catch (error) {
    console.log('error', error);
  }
  return carrierData;
};

const sendCommmand = async (payload) => {
  const { apiKey, assetId, command, commandValue, runMode, defrostInit, sleepMode, truOnOff } = payload;

  try {
    const myHeaders = new Headers();
    myHeaders.append('x-lynx-api-key', apiKey);
    myHeaders.append('Content-Type', 'application/json');
    const raw = JSON.stringify({
      assetId,
      ...(command !== undefined ? { [command]: commandValue } : {}),
      ...(runMode !== '' ? { RunMode: runMode } : {}),
      //de momento no se sabe si se puede parar con un false👇
      ...(defrostInit !== false ? { DefrostInitiation: defrostInit } : {}),
      // ...(defrostInit !== '' ? { DefrostInitiation: defrostInit } : {}),
      ...(sleepMode !== '' ? { SleepMode: sleepMode } : {}),
      ...(truOnOff !== '' ? { TRUOnOff: truOnOff } : {}),
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const response = await fetch('https://api.fleet.lynx.carrier.io/2waycmd/v1/send-commands', requestOptions);
    const result = await response.text();
    const resultJSON = JSON.parse(result);

    return resultJSON.data[0];
  } catch (error) {
    console.log('error', error);
  }
};

const checkCommandStatus = async (apiKey, assetId, commandId) => {
  const myHeaders = new Headers();
  myHeaders.append('x-lynx-api-key', apiKey);

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  let resultJSON;
  try {
    let response;

    if (commandId) {
      response = await fetch(`${urlCommands}check-command-status?assetId=${assetId}&commandId=${commandId}`, requestOptions);
    } else {
      response = await fetch(`${urlCommands}check-command-status?assetId=${assetId}`, requestOptions);
    }

    const result = await response.text();
    resultJSON = JSON.parse(result);
  } catch (error) {
    console.log('error', error);
  }

  return resultJSON;
};

module.exports = { getCarrierAssets, getFrameMachineFromCarrier, sendCommmand, checkCommandStatus };
