<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0" style="height: 30em !important">
      <div
        class="text-overline font-weight-bold"
        style="display: flex; justify-content: space-between; align-items: center; width: 100%">
        <span>
          {{ $t('dashboard.details.cold_machine') }}
        </span>
        <span class="text-overline font-weight-bold" v-if="apiKeyCarrier"> --API {{ apiKeyCarrier ? 'CARRIER' : '' }}-- </span>
      </div>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div class="ml-1 d-flex flex-column" style="margin-bottom: 2em">
        <span>
          <v-icon :class="checkStatus" x-small> mdi-circle </v-icon>
          {{ carrierTimestamp ?? coldMachineTimestamp }}
        </span>
      </div>
      <v-row>
        <v-col>
          <!------------------------------------------ CHANGE SET POINT---------- -->
          <v-row v-if="canChangeSetpoint" class="justify-space-around">
            <v-chip
              v-if="!apiKeyCarrier"
              label
              small
              class="justify-center ma-1 white--text blue-grey lighten-1"
              :style="{
                backgroundColor: colorSetpoint,
                color: pretrip_received ? 'white !important' : '',
                width: '50%',
              }">
              <span @click="openModal('setpoint')">
                <div>
                  <v-icon
                    :style="{
                      color: theme === 'dark' ? 'white !important' : '',
                      margin: '0.2em',
                    }"
                    >mdi-thermometer-plus</v-icon
                  >
                </div>
              </span>
              <span> {{ $t('dashboard.configuration.modify_setpoint') }}{{ tempSetpoint ?? setpoint }} </span>
            </v-chip>
            <v-chip
              v-else
              label
              small
              class="justify-center ma-1 white--text blue-grey lighten-1"
              :style="{
                backgroundColor: colorSetpoint,
                color: pretrip_received ? 'white !important' : '',
                width: '50%',
              }">
              <span style="cursor: pointer" @click="openModal('command')">
                <v-row>
                  <v-icon
                    :style="{
                      color: theme === 'dark' ? 'white !important' : '',
                      margin: '0.2em',
                    }"
                    >mdi-thermometer-plus</v-icon
                  >
                  <span style="margin: 0.5em">{{ $t('dashboard.configuration.modify_setpoint') }} </span>
                </v-row>
              </span>
            </v-chip>
            <v-chip
              :disabled="!apiKeyCarrier"
              label
              small
              class="justify-center ma-1 blue-grey lighten-4"
              :style="{
                backgroundColor: colorPretrip,
                color: pretrip_received && pretrip ? 'white !important' : '',
                margin: '0.2em',
                width: '35%',
              }">
              <span style="cursor: pointer" @click="openModal('moreCommands')">
                <v-row>
                  <v-icon
                    :style="{
                      color: theme === 'dark' ? 'black !important' : '',
                      margin: '0.2em',
                    }"
                    >mdi-tune</v-icon
                  >
                  <span style="margin: 0.5em">{{ $t('dashboard.configuration.controls') }}</span>
                </v-row>
              </span>
            </v-chip>

            <v-divider class="py-2 mx-6 mt-3" style="border-color: #607d8b"></v-divider>
          </v-row> </v-col
      ></v-row>
      <!------------------------------------------ API CARRIER(Abajo)---------------------------------------------------------------------- -->
      <v-row v-if="apiKeyCarrier">
        <v-col>
          <!------------------------------------------ FUELL LEVEL---------- -->
          <v-row class="justify-space-around">
            <v-chip
              label
              small
              class="justify-center ma-1 white--text blue-grey lighten-1"
              style="width: 50%; margin-top: 3em !important">
              {{ $t('dashboard.details.fuel_level') }} {{ this.fuelLevel }} %
            </v-chip>

            <div class="justify-center ma-1" style="width: 35%; margin-top: 0.7em !important">
              <div class="fuel-gauge">
                <div class="gauge">
                  <div class="semi-circle">
                    <div class="ticks">
                      <div v-for="tick in ticks" :key="tick" class="tick" :style="{ transform: `rotate(${tick}deg)` }"></div>
                    </div>
                    <div class="labels">
                      <span class="label" style="left: 10px">E</span>
                      <span class="label" style="right: 5px">F</span>
                    </div>
                    <div class="labels1">
                      <span class="label" style="left: 30%">¼</span>
                    </div>
                    <div class="labels2">
                      <span class="label" style="left: 50%">½</span>
                    </div>
                    <div class="labels3">
                      <span class="label" style="left: 70%">¾</span>
                    </div>
                  </div>
                  <div class="needle" :style="{ transform: `rotate(${needleRotation}deg)` }"></div>
                </div>
              </div>
            </div>
          </v-row>
          <v-row class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t('dashboard.details.status') }}
            </v-chip>
            <v-chip label small class="justify-center ma-1" style="width: 35%" :color="powerStatus ? 'green' : 'grey'">
              <span style="color: white"> {{ powerStatus ? $t('dashboard.details.on') : $t('dashboard.details.off') }} </span>
            </v-chip>
          </v-row>
          <!------------------------------------------ MODO DE TRABAJO--------- -->
          <v-row class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t('dashboard.details.working_mode') }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span>
                {{ engineControlMode == 'Continuous' ? $t('dashboard.details.continuous') : $t('dashboard.details.other') }}</span
              >
            </v-chip>
          </v-row>

          <!------------------------------------------ DATA------------------ -->
          <v-row class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t(`dashboard.configuration.setpointTemp1`) }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span v-if="howManyMinutes >= 60"> --- </span>
              <span v-else> {{ setpoint }}ºC </span>
            </v-chip>
          </v-row>
          <v-row class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t(`dashboard.configuration.returnAirTemp1`) }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span v-if="howManyMinutes >= 60"> --- </span>
              <span v-else>{{ returnData }}ºC </span>
            </v-chip>
          </v-row>
          <v-row class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t(`dashboard.configuration.supplyAirTemp1`) }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span v-if="howManyMinutes >= 60"> --- </span>
              <span v-else>{{ supply }}ºC</span>
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
      <!------------------------------------------ INFO DE BBDD(Abajo)---------------------------------------------------------------------- -->
      <v-row v-else>
        <v-col>
          <!------------------------------------------ STATUS---------- -->
          <v-row class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t('dashboard.details.status') }}
            </v-chip>
            <v-chip
              label
              small
              class="justify-center ma-1"
              style="width: 35%"
              :color="getMachineStatus && diffMinutesFromColdMachine < 60 ? 'green' : 'grey'">
              <span style="color: white">
                {{
                  getMachineStatus && diffMinutesFromColdMachine < 60 ? $t('dashboard.details.on') : $t('dashboard.details.off')
                }}
              </span>
            </v-chip>
          </v-row>

          <!------------------------------------------ MODO DE TRABAJO Desde BBDD---------- -->
          <v-row v-if="getMachineMode" class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t('dashboard.details.working_mode') }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span>{{ getMachineMode }} </span>
            </v-chip>
          </v-row>
          <!------------------------------------------ TODAS LAS TEMPKEY ( Setpoint, ambient, supply, return)- BBDD---------- -->

          <v-row class="justify-space-around" v-for="[tempKey, value] in getTemps" v-bind:key="tempKey">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t(`dashboard.details.${tempKey}`) }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span v-if="tempKey == 'temp_setpoint'" @click="openModal">
                <div>
                  <span v-if="value && diffMinutesFromColdMachine < 60">{{ value }}ºC</span>
                  <span v-else> --- </span>
                </div>
              </span>
              <span v-if="tempKey !== 'temp_setpoint' && value && diffMinutesFromColdMachine < 60">{{ value }}ºC </span>
              <span v-if="tempKey !== 'temp_setpoint' && value && diffMinutesFromColdMachine >= 60"> --- </span>
              <span v-if="tempKey !== 'temp_setpoint' && !value"> --- </span>
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getUser } from '../../../../viewModels/userViewModel';
import { eventBus } from '../../../../main';
import { getFrameMachineFromCarrier } from '../../../../utils/apiCarrier';

const ls = require('localstorage-slim');

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: Object,
    },
    coldMachineTimestamp: {
      type: String,
      default: '',
    },
    diffMinutesFromColdMachine: {
      type: Number,
    },
  },
  data: () => ({
    pretrip: false,
    pretrip_received: false,
    timeout: false,
    firstCANCol: [],
    secondCANCol: [],
    canChangeSetpoint: false,
    theme: ls.get('theme') ?? 'ligth',
    tempP1: '',
    colorPretrip: '',
    colorSetpoint: '',
    tempSetpoint: '',
    colorImportant: 'rgb(65, 172, 86) !important',
    getFrameMachineFromCarrier: null,
    apiKeyCarrier: null,
    setpoint: null,
    returnData: null,
    supply: null,
    powerStatus: null,
    engineControlMode: null,
    fuelLevel: 100,
    ticks: [-90, -45, 0, 45, 90],
    howManyMinutes: null,
  }),

  computed: {
    carrierTimestamp() {
      if (this.getFrameMachineFromCarrier)
        return this.getTimestampTooltip(this.getFrameMachineFromCarrier?.data.at(-1).eventDateTimeUTC, new Date());
    },
    frameFrigoMachine() {
      return this.device?.last_frames?.frame_frigo_machine;
    },
    frigoMachine() {
      return this.frameFrigoMachine?.frigo_machine;
    },
    // frigoMachineType() {
    //   return this.frigoMachine?.machine_type;
    // },
    checkStatus() {
      return this.status.coldMachine ? 'green--text' : 'gray--text';
    },
    getTemps() {
      if (!this.frigoMachine) return [];
      let temps = Object.keys(this.frigoMachine).filter((key) => key.startsWith('temp_'));
      return Object.entries(this.frigoMachine).filter(([key, value]) => temps.includes(key));
    },
    getMachineStatus() {
      return this.frigoMachine?.machine_status?.machine_on ?? false;
    },
    getMachineMode() {
      return this.frigoMachine?.machine_status?.mode ?? false;
    },
    needleRotation() {
      return this.fuelLevel * 1.8 - 90;
    },
  },
  methods: {
    openModal(options) {
      // TODO
      if (options === 'pretrip') {
        eventBus.$emit('isPretrip');
      }
      let params = {
        assetId: this.device.third_party_services?.carrier?.asset_id,
        apiKey: this.device.third_party_services?.carrier?.api_key,
        moreCommands: options === 'moreCommands',
      };
      if (this.apiKeyCarrier) return this.$emit('openModalCommand', params);
      if (this.canChangeSetpoint) this.$emit('openModalSetPoint');
    },
    getTimestampTooltip(timestampDate, actualDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      const date = new Date(timestampDate);
      const formatedDate = this.formatDate(timestampDate);
      const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));
      if (diffDays < 1) {
        const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
        if (diffHours > 1) {
          return this.$t('dashboard.details.last_measure_hours', {
            hours: diffHours,
          });
        }
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
        this.howManyMinutes = diffMinutes;
        if (diffMinutes > 1) {
          return this.$t('dashboard.details.last_measure_minutes', {
            minutes: diffMinutes,
          });
        }

        return this.$t('dashboard.details.last_measure_recently');
      }

      if (diffDays < 7) {
        return this.$t('dashboard.details.last_measure_in_days', {
          days: diffDays,
        });
      }

      return this.$t('dashboard.details.last_measure_date', {
        date: formatedDate.date,
      });
    },
    formatDate(deviceTimestamp) {
      let date = new Date(deviceTimestamp);
      let minute = date.getMinutes();
      if (minute.toString().length < 2) minute = '0' + minute;
      let hour = date.getHours();
      if (hour.toString().length < 2) hour = '0' + hour;
      let day = date.getDate();
      if (day.toString().length < 2) day = '0' + day;
      let month = date.getMonth() + 1;
      if (month.toString().length < 2) month = '0' + month;
      let year = date.getFullYear();

      let formatedDate = {
        date: `${day}/${month}/${year}`,
        hours: `${hour}:${minute}`,
      };
      return formatedDate;
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    eventBus.$on('pretrip-received', (setpoint, isPretrip) => {
      this.pretrip_received = true;

      if (isPretrip) {
        this.colorPretrip = '';
        this.tempP1 = Number(setpoint);

        setTimeout(() => {
          if (isPretrip) this.tempP1 = Number(setpoint) + 2 + 'ºC';
          this.pretrip = isPretrip;
          this.colorPretrip = this.pretrip_received && this.pretrip ? this.colorImportant : '';
        }, 10000);
      }

      if (!isPretrip) {
        this.colorSetpoint = '';
        this.tempSetpoint = Number(setpoint);

        setTimeout(() => {
          if (!isPretrip) this.tempSetpoint = Number(setpoint) + 'ºC';
          this.colorSetpoint = this.pretrip_received ? this.colorImportant : '';
        }, 5000);
      }
    });

    const user = await getUser();
    this.canChangeSetpoint = user.restrictions.features.change_setpoint === false ? true : false;
    this.apiKeyCarrier = this.device.third_party_services?.carrier?.api_key;
    const assetIdCarrier = this.device.third_party_services?.carrier?.asset_id;
    let startDate = new Date(new Date().setHours(new Date().getHours() - 1)).toISOString();
    let endDate = new Date().toISOString();
    if (this.apiKeyCarrier) {
      this.getFrameMachineFromCarrier = await getFrameMachineFromCarrier(this.apiKeyCarrier, assetIdCarrier, startDate, endDate);

      let frameIndex = 0;
      this.getFrameMachineFromCarrier.data.map((data, index) => {
        if (data.temperatureInfo.setpointTemp1) frameIndex = index;
      });

      Object.entries(this.getFrameMachineFromCarrier.data[frameIndex]?.temperatureInfo).forEach((element, index) => {
        if (element[0].includes('Temp1') && element[0] != 'datacoldTemp1' && element[0] != 'oneWireTemp1') {
          this.status.coldMachine = true;
          this.setpoint = this.getFrameMachineFromCarrier.data[frameIndex].temperatureInfo.setpointTemp1;
          this.returnData = this.getFrameMachineFromCarrier.data[frameIndex].temperatureInfo.returnAirTemp1;
          this.supply = this.getFrameMachineFromCarrier.data[frameIndex].temperatureInfo.supplyAirTemp1;
        }
      });
      this.powerStatus = this.getFrameMachineFromCarrier.data.at(-1).statusInfo.powerStatus;
      this.engineControlMode = this.getFrameMachineFromCarrier.data.at(-1).statusInfo.engineControlMode;
      this.fuelLevel = this.getFrameMachineFromCarrier.data.at(-1).statusInfo.fuelLevel;
    }
    if (this.device) this.getNumberOfColums;
  },
};
</script>
<style scoped>
.fuel-gauge {
  position: relative;
  width: 100px;
  margin-left: 10px;
}

.gauge {
  position: relative;
  width: 100px;
  height: 50px;
  overflow: hidden;
}

.semi-circle {
  width: 100%;
  height: 100%;
  background: #607d8b;
  border-radius: 150px 150px 0 0;
  position: relative;
  border: 2px solid #555;
  box-sizing: border-box;
}

.ticks {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.tick {
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 1px;
  height: 1em;
  background: #555;
  transform-origin: bottom center;
}
/* E F */
.labels {
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
  color: #fff;
  font-family: Arial, sans-serif;
}
/* 1/3 */
.labels1 {
  position: absolute;
  bottom: 40px;
  left: 25px;
  color: #fff;
  font-family: Arial, sans-serif;
}
/* 1/2 */
.labels2 {
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  color: #fff;
  font-family: Arial, sans-serif;
}
/* 3/4 */
.labels3 {
  position: absolute;
  bottom: 40px;
  left: 23px;
  right: 0;
  color: #fff;
  font-family: Arial, sans-serif;
}

.label {
  position: absolute;
  transform: translateX(-50%);
  font-size: 10px;
}

.needle {
  position: absolute;
  width: 4px;
  height: 35px;
  background: #ff0000;
  bottom: 0;
  left: 50%;
  transform-origin: bottom center;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}
</style>
