<template>
  <v-dialog v-model="isActive" max-width="85rem" @click:outside="cancelSetpoint" @keydown.esc="cancelSetpoint">
    <v-card class="ticket-view-card" style="max-height: 90vh; overflow-y: auto">
      <v-card-title class="pt-3" color="primary" style="justify-content: space-between; font-size: 1em; height: 1.8em">
        <span>{{ this.$t('dashboard.configuration.send_commands_to_api') }}</span>
        <span @click="cancelSetpoint" class="ticket-view-modal-title-btn">
          <font-awesome-icon style="color: red; cursor: pointer" icon="fa-solid fa-circle-xmark" class="close-ticket-promp" />
        </span>
      </v-card-title>
      <v-card-title color="primary" class="caption pt-3">
        S/N: {{ device.device_sn }} -- Tru serial: {{ device.third_party_services?.carrier?.tru_serial_number }}</v-card-title
      >

      <v-divider class="py-3 mx-2" style="border-color: #607d8b"></v-divider>
      <v-row v-if="api.moreCommands" style="display: flex; justify-content: center; margin-top: 1em">
        <v-select
          dense
          :items="runModeItems"
          :label="this.$t('dashboard.configuration.operation')"
          v-model="runMode"
          style="width: 8em; display: flex; height: 1em; margin-bottom: 1em; padding: 0em 2em"
          class="small-text" />
        <v-select
          dense
          :items="sleepModeItems"
          :label="this.$t('dashboard.configuration.sleep_mode')"
          v-model="sleepMode"
          style="width: 8em; display: flex; height: 1em; margin-bottom: 1em; padding: 0em 2em"
          class="small-text" />
        <v-select dense :items="truOnOffItems" label="TRU ON/OFF" v-model="truOnOff" style="width: 8em; display: flex; height:
        1em; margin-bottom: 1em;padding: 0em 2em"" class="small-text" />
      </v-row>
      <v-row v-if="api.moreCommands" style="display: flex; justify-content: center">
        <v-checkbox
          v-model="defrostInitiation"
          :label="this.$t('dashboard.configuration.start_thawing')"
          dense
          class="small-checkbox"
          style="display: flex; justify-self: center" />
      </v-row>
      <v-row v-if="!api.moreCommands" style="justify-content: space-around">
        <CommandsApiSelector
          v-for="(command, indexCommand) in this.commands"
          :key="indexCommand"
          :compartmentSetpoint="command.command"
          @editing="setEditing(indexCommand)"
          :disabled="editingIndex !== null && editingIndex !== indexCommand"
          @finish-editing="resetEditing" />
      </v-row>
      <v-form>
        <v-container class="text-md-center mt-2">
          <v-btn class="mx-lg-6" color="error" elevation="3" @click="cancelSetpoint" x-small outlined>
            {{ this.$t('dashboard.configuration.cancel') }}
          </v-btn>
          <v-btn :disabled="disabledButton" class="mx-lg-6" color="primary" elevation="3" @click="sentCommand" x-small outlined>
            {{ this.$t('dashboard.configuration.send') }}
          </v-btn>
        </v-container>
      </v-form>

      <!-- :items-per-page="-1" -->
      <v-data-table
        :items-per-page="10"
        :sort-desc="true"
        sort-by="createdAt"
        :custom-sort="customSort"
        :headers="headers"
        :items="commandItems"
        class="elevation-1 small-table mt-3"
        height="300px"
        dense
        hide-default-footer
        :style="{
          background: theme === 'dark' ? '#f7f7f73d !important' : '',
          width: '70em',
          'max-width': '90%',
          margin: 'auto',
          marginBottom: '1em',
          color: theme === 'dark' ? 'white' : 'black',
        }" />
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
      {{ text }}
    </v-snackbar>
    <v-dialog v-if="isActiveDialog" v-model="isActiveDialog" v-on:cancel-command="cancelDelivery" max-width="20rem">
      <v-card>
        <v-card-title color="primary" class="headline">{{ t('dashboard.configuration.send_command') }}</v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <p style="color: black !important">{{ t('dashboard.configuration.warning_command') }}.</p>
        </v-card-text>
        <v-form>
          <v-container class="text-md-center mt-2">
            <v-btn class="mx-lg-6" color="error" elevation="3" @click="cancelDelivery" x-small outlined>
              {{ this.$t('dashboard.configuration.cancel') }}
            </v-btn>
            <v-btn :disabled="disabledButton" class="mx-lg-6" color="primary" elevation="3" @click="save" x-small outlined>
              {{ this.$t('general.form.accept') }}
            </v-btn>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { checkCommandStatus, sendCommmand } from '../../utils/apiCarrier';
import CommandsApiSelector from '../../components/api/commandsApiSelector.vue';
import { eventBus } from '../../main';
import { formatDateTable } from '../../utils/utils';
import { saveCommand } from '../../viewModels/commandsViewModel';
const ls = require('localstorage-slim');

export default {
  components: {
    CommandsApiSelector,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
    api: {
      type: Object,
    },
    user: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    nameOnSelector: '',
    setpointOnSelector: '',
    snackbarColor: 'default',
    disabledButton: true,
    snackbar: false,
    text: '',
    setpoint: '',
    theme: ls.get('theme') ?? 'ligth',
    pretrip: false,
    commandItems: [],
    commands: null,
    runMode: '',
    defrostInitItems: ['true', 'false'],
    runModeItems: ['Continuous', 'Start/Stop'],
    sleepModeItems: ['ON', 'OFF'],
    truOnOffItems: ['ON', 'OFF'],
    defrostInitiation: false,
    defrostInit: '',
    sleepMode: '',
    truOnOff: '',
    editingIndex: null,
    commandName: '',
    commandValue: null,
    isActiveDialog: false,
    cancelDialog: false,
  }),
  computed: {
    async getCommands() {
      let command = null;
      const checkCommand = await checkCommandStatus(this.api.apiKey, this.api.assetId);
      for (const cmd of checkCommand) {
        command = {
          commandName: cmd.commandName,
          status: cmd.status,
          deviceName: cmd.deviceName,
          createdAt: formatDateTable(cmd.createdOn),
          response: cmd.responseOn ? formatDateTable(cmd.responseOn) : '',
          createdBy: cmd.createdBy,
        };
        this.commandItems.push(command);
      }

      this.commands = [
        { command: `${this.$t('dashboard.configuration.compartment')} 1`, statusDisabled: false },
        { command: `${this.$t('dashboard.configuration.compartment')} 2`, statusDisabled: false },
        { command: `${this.$t('dashboard.configuration.compartment')} 3`, statusDisabled: false },
      ];
    },
    maxNameLengthRule() {
      this.setpoint = this.device.user_actions?.setpoint;

      return [
        (value) => Number(value) >= -25 || this.$t('dashboard.configuration.temp_min_setpoint'),
        (value) => Number(value) <= 25 || this.$t('dashboard.configuration.temp_max_setpoint'),
      ];
    },
    getSetpoint() {
      return this.api;
    },
    headers() {
      return [
        {
          text: `${this.$t('dashboard.configuration.command')}`,
          align: 'start',
          sortable: false,
          value: 'commandName',
          divider: true,
        },
        { text: `${this.$t('dashboard.details.status')}`, value: 'status', divider: true },
        { text: `${this.$t('dashboard.configuration.device')}`, value: 'deviceName', divider: true },
        { text: `${this.$t('dashboard.configuration.date_sent')}`, value: 'createdAt', divider: true },
        { text: `${this.$t('dashboard.configuration.date_of_response')}`, value: 'response', divider: true },
        { text: `${this.$t('dashboard.configuration.sent_by')}`, value: 'createdBy', divider: true },
      ];
    },
  },
  methods: {
    convertDate(dateStr) {
      const parts = dateStr.split('-');
      const [day, month, year] = parts;
      // Le quitamos 1 por que empieza por el indice 0
      return new Date(year, month - 1, day).getTime();
    },

    customSort(items, sortBy, sortDesc) {
      if (!sortBy.length) return items;
      const key = sortBy[0];
      const desc = sortDesc[0];
      return items.sort((a, b) => {
        let aVal = a[key];
        let bVal = b[key];

        if (key === 'createdAt') {
          aVal = this.convertDate(aVal);
          bVal = this.convertDate(bVal);
        }

        if (aVal < bVal) return desc ? 1 : -1;
        if (aVal > bVal) return desc ? -1 : 1;
        return 0;
      });
    },

    checkButtonState() {
      const regex = /^(?!.*[~˜])[a-zA-Z0-9-\s]{1,20}$/;

      const isValidName = this.nameOnSelector && this.nameOnSelector.length <= 20 && regex.test(this.nameOnSelector);
      const isValidSetpoint =
        this.setpointOnSelector !== '' && Number(this.setpointOnSelector) >= -30 && Number(this.setpointOnSelector) <= 35;
      const hasActiveMode = this.runMode || this.defrostInitiation || this.sleepMode || this.truOnOff;

      this.disabledButton = !(
        (hasActiveMode && !this.nameOnSelector && !this.setpointOnSelector) ||
        (isValidName && !this.setpointOnSelector) ||
        (isValidName && isValidSetpoint) ||
        (isValidSetpoint && !this.nameOnSelector)
      );

      const isReset = !this.setpointOnSelector && !this.nameOnSelector && !this.runMode && !this.sleepMode && !this.truOnOff;
      if (isReset) this.disabledButton = true;
    },
    setEditing: function (index) {
      this.editingIndex = index;
      if (index == 0) this.commandName = 'CompartmentSetpoint1'; //LANG
      if (index == 1) this.commandName = 'CompartmentSetpoint2'; //LANG
      if (index == 2) this.commandName = 'CompartmentSetpoint3'; //LANG
    },
    resetEditing: function () {
      this.editingIndex = null;
    },
    cancelSetpoint: function () {
      this.$emit('cancel-command');
      eventBus.$emit('close-command-modal');
      this.runMode = '';
      this.defrostInitiation = false;
      this.nameOnSelector = '';
      this.setpointOnSelector = '';
      this.sleepMode = '';
      this.truOnOff = '';
    },
    cancelDelivery: function () {
      this.isActiveDialog = false;
    },
    clearForm: function () {
      eventBus.$emit('close-command-modal');
      this.runMode = '';
      this.defrostInitiation = false;
      this.nameOnSelector = '';
      this.setpointOnSelector = '';
      this.sleepMode = '';
      this.truOnOff = '';
    },
    sentCommand: function () {
      this.isActiveDialog = true;
    },
    save: async function () {
      try {
        const payload = {
          apiKey: this.api.apiKey,
          assetId: this.api.assetId,
          command: this.commandName,
          commandValue: Number(this.setpointOnSelector),
          runMode: this.runMode,
          defrostInit: this.defrostInitiation,
          sleepMode: this.sleepMode,
          truOnOff: this.truOnOff,
        };
        let res = await sendCommmand(payload);
        let checkCommand = await checkCommandStatus(this.api.apiKey, this.api.assetId, res.commandId);

        this.isActiveDialog = false;

        const command = {
          commandName: checkCommand[0].commandName,
          status: checkCommand[0].status,
          deviceName: checkCommand[0].deviceName,
          createdAt: checkCommand[0].createdOn,
          response: checkCommand[0].responseOn,
          createdBy: checkCommand[0].createdBy,
        };

        this.commandItems.push(command);

        let commandPayload = {
          deviceSn: this.device.device_sn,
          snApi: this.device.third_party_services?.carrier?.tru_serial_number,
          alias: 'setpoint',
          commandName: checkCommand[0].commandName,
          value: Number(this.setpointOnSelector),
          email: this.user.email,
          typeCommand: 'apache',
          status: checkCommand[0].status,
          createdByApi: checkCommand[0].createdBy,
        };

        await saveCommand(commandPayload);

        if (res != undefined) {
          this.snackbar = true;
          this.snackbarColor = 'success';
          this.text = this.$t('dashboard.configuration.request_sent');
          setTimeout(() => {
            this.clearForm();
          }, 1000);
        }
      } catch (e) {
        this.snackbar = true;
        this.snackbarColor = 'error';
        this.text = this.$t('dashboard.configuration.error_req');
        setTimeout(() => {
          this.clearForm();
        }, 2000);
      }
    },
  },
  watch: {
    runMode() {
      this.checkButtonState();
    },
    defrostInitiation() {
      this.checkButtonState();
    },
    sleepMode() {
      this.checkButtonState();
    },
    truOnOff() {
      this.checkButtonState();
    },
    nameOnSelector() {
      this.checkButtonState();
    },
    setpointOnSelector() {
      this.checkButtonState();
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    eventBus.$on('isPretrip', () => {
      this.pretrip = true;
    });

    eventBus.$on('selectorSetpointData', (setpoint) => {
      this.setpointOnSelector = setpoint;
    });
    eventBus.$on('selectorNameData', (name) => {
      this.nameOnSelector = name;
    });

    this.getCommands;
  },
};
</script>
